import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import BottomShapeDivider from '~components/ShapeDividers/BottomShapeDivider';
import Link from '~components/Link';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '106px',
    paddingBottom: '190px',
    gap: 50,
    [theme.breakpoints.down('xs')]: {
      gap: 20,
      paddingTop: '60px',
      paddingBottom: '130px'
    }
  },
  section: {
    position: 'relative',
    backgroundColor: props => props.section_background_color
  },
  title: {
    fontWeight: 500,
    fontFamily: 'Inter',
    textAlign: 'center',
    color: '#1D1927',
    fontSize: '44px',
    lineHeight: '115%',
    marginTop: 0,
    marginBottom: 0,
    maxWidth: '800px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '25px'
    }
  },
  button: props => ({
    display: 'block',
    width: 'fit-content',
    fontWeight: 500,
    borderRadius: 40,
    whiteSpace: 'nowrap',
    zIndex: 10,
    fontSize: 32,
    padding: '20px 64px',
    [theme.breakpoints.down('xs')]: {
      fontFamily: 'Inter',
      padding: '13px 31px',
      fontSize: 16
    },
    color: props.cta_button_text_color,
    backgroundColor: props.cta_button_color,
    '&:hover': {
      opacity: 0.9,
      textDecoration: 'none'
    },
    '&:active': {
      textDecoration: 'none'
    }
  })
}));

const CtaButtonSection = props => {
  const {
    title,
    cta_button_text,
    cta_url,
    cta_button_color,
    cta_button_text_color,
    section_background_color,
    bottom_shape_divider
  } = props;
  const classes = useStyles({
    cta_button_color,
    cta_button_text_color,
    section_background_color
  });

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <h2 className={classes.title}>{title}</h2>
        <Link to={cta_url} className={classes.button}>
          {cta_button_text}
        </Link>
      </div>
      {/* Shape Divider */}
      {bottom_shape_divider?.url && (
        <BottomShapeDivider src={bottom_shape_divider.url} bgColor="transparent" flex />
      )}
    </div>
  );
};

CtaButtonSection.propTypes = {
  title: PropTypes.string.isRequired,
  cta_button_text: PropTypes.string.isRequired,
  cta_url: PropTypes.string.isRequired,
  cta_button_color: PropTypes.string,
  cta_button_text_color: PropTypes.string,
  section_background_color: PropTypes.string,
  bottom_shape_divider: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string
  })
};

CtaButtonSection.defaultProps = {
  cta_button_color: '#000000',
  cta_button_text_color: '#FFFFFF',
  section_background_color: '#000000',
  bottom_shape_divider: null
};

export default CtaButtonSection;
